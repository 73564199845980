import { Container, Nav, Navbar } from "react-bootstrap";
import { Link, Outlet} from "react-router-dom";
import React from "react";
function WebLayout(){
    return (
        <>
            <Navbar bg="dark" variant="dark" sticky="top">
            <Container>
                <Nav className="me-auto">
                    <Nav.Link as={Link} to="/">Home</Nav.Link>
                    <Nav.Link as={Link} to="/education">Education</Nav.Link>
                    <Nav.Link as={Link} to="/activities">Activities</Nav.Link>
                    <Nav.Link as={Link} to="/projects">Projects</Nav.Link>
                    <Nav.Link as={Link} to="/rythmroadtrip">Rythm RoadTrip</Nav.Link>
                </Nav>
            </Container>
        </Navbar>
        <Outlet></Outlet>
    </>);
}
export default WebLayout;