import './RecentlyPlayed.css';
import React from 'react';
import { Card, Row, Col, Button } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';


const RecentlyPlayed = ({ tracks }) => {
  return (
    <div className="recently-played-container">
      <Row className="recently-played">
        {tracks.slice(0, 5).map((track, index) => (
          <Col key={index} xs={12} sm={6} md={4} lg={2} className="d-flex align-items-stretch mb-4">
            <Card className="recently-played-card">
              <Card.Img variant="top" src={track.images[0].url} alt={track.name} className="album-image" />
              <Card.Body className="card-content">
                <Card.Title className="track-name">{track.name}</Card.Title>
                <Card.Text className="artist-names">{track.artists.join(', ')}</Card.Text>
                <Card.Text className="played-at">{new Date(track.playedAt).toLocaleString()}</Card.Text>
                <Button variant="success" href={track.url} target="_blank" rel="noopener noreferrer">
                  Listen on Spotify
                </Button>
              </Card.Body>
            </Card>
          </Col>
        ))}
      </Row>
    </div>
  );
};

export default RecentlyPlayed;



// const RecentlyPlayed = ({ tracks }) => {
//   return (
//     <div className="recently-played-container">
//       <div className="recently-played">
//         {tracks.slice(0, 5).map((track, index) => (
//           <div className="recently-played-card" key={index}>
//             <img className="album-image" src={track.images[0].url} alt={track.name} />
//             <div className="card-content">
//               <h3 className="track-name">{track.name}</h3>
//               <p className="artist-names">{track.artists.join(', ')}</p>
//               <p className="played-at">{new Date(track.playedAt).toLocaleString()}</p>
//               <a className="spotify-link" href={track.url} target="_blank" rel="noopener noreferrer">
//                 Listen on Spotify
//               </a>
//             </div>
//           </div>
//         ))}
//       </div>
//     </div>
//   );
// };

// export default RecentlyPlayed;
