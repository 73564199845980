import React from 'react';
import { Container, Row, Col, Button, Alert } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import './SpotifyAuthRequest.css'

export default function SpotifyAuthRequest(props){
    return (
        <Container className="d-flex align-items-center justify-content-center text-white">
          <Row className="text-center">
            <Col>
              <Alert variant="success">
                <Alert.Heading>Spotify Authorization Required</Alert.Heading>
                <p>To use this feature you must authorize your Spotify account.</p>
                <hr />
                <Button variant="success" size="lg" onClick={() => props.spotifyEnpoint()}>
                  Authorize Spotify
                </Button>
              </Alert>
            </Col>
          </Row>
        </Container>
      );
}