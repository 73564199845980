import React from 'react';

import './App.css';
import PersonalApp from './structural/PersonalApp';

function App() {
  return <PersonalApp/>
}

export default App;
