import React from 'react';
import SemesterCourses from '../components/SemesterCourses';
import { Container } from 'react-bootstrap';
import EducationIntroduction from './EducationIntroduction';
const semesters = [
    {
        "termDescription": "Fall 2021-2022",
        "gpa": 3.654,
        "courses": [
            {
                "courseDescription": "The Evolving Universe",
                "courseNum": "103",
                "courseShortDescription": "ASTRON",
                "description": "The cosmos is vast, mysterious, and beautiful. Join us on an exploration of the universe, from the big bang to the birth, life, and death of stars and the warped reality of black holes. Includes lifecycles of stars; supernovae and creation of elements; white dwarfs, pulsars and black holes; the Milky Way and galaxies; distances of stars and galaxies; quasars; expansion of universe; modern big bang cosmology, dark matter, dark energy.",
                "credits": "3 credits."
            },
            {
                "courseDescription": "Introduction to Psychology",
                "courseNum": "202",
                "courseShortDescription": "PSYCH",
                "description": "Behavior, including its development, motivation, frustrations, emotion, intelligence, learning, forgetting, personality, language, thinking, and social behavior.",
                "credits": "3-4 credits."
            },
            {
                "courseDescription": "Science, Media and Society",
                "courseNum": "251",
                "courseShortDescription": "LSC",
                "description": "Introduction to communication at the intersection of science, politics and society; overview of the theoretical foundations of science communication and their relevance for societal debates about science and emerging technologies across different parts of the world.",
                "credits": "3 credits."
            },
            {
                "courseDescription": "Pers & Prof Foundation in Bus",
                "courseNum": "110",
                "courseShortDescription": "GEN BUS",
                "description": "An introduction for new business students covering academic exploration and planning, career development, self-assessment for personal development, leadership, and diversity and inclusion.",
                "credits": "1 credit."
            },
            {
                "courseDescription": "Programming II",
                "courseNum": "300",
                "courseShortDescription": "COMP SCI",
                "description": "Introduction to Object-Oriented Programming using classes and objects to solve more complex problems. Introduces array-based and linked data structures: including lists, stacks, and queues. Programming assignments require writing and developing multi-class (file) programs using interfaces, generics, and exception handling to solve challenging real world problems. Topics reviewed include reading/writing data and objects from/to files and exception handling, and command line arguments. Topics introduced: object-oriented design; class vs. object; create and define interfaces and iterators; searching and sorting; abstract data types (List,Stack,Queue,PriorityQueue(Heap),Binary Search Tree); generic interfaces (parametric polymorphism); how to design and write test methods and classes; array based vs. linked node implementations; introduction to complexity analysis; recursion.",
                "credits": "3 credits."
            }
        ]
    },
    {
        "termDescription": "Spring 2021-2022",
        "gpa": 3.406,
        "courses": [
            {
                "courseDescription": "Black Music&Am Cultrl Hist",
                "courseNum": "156",
                "courseShortDescription": "AFROAMER",
                "description": "Examines the interaction between African American musical culture and its historical context, with an emphasis on the period from 1920 to the present.",
                "credits": "3 credits."
            },
            {
                "courseDescription": "Intro Financial Accounting",
                "courseNum": "100",
                "courseShortDescription": "ACCT I S",
                "description": "Examines generally accepted accounting principles for measurement and reporting of financial information in a balance sheet, income statement, and statement of cash flows; introduction to analysis and interpretation of financial accounting data for decision-making purposes.",
                "credits": "3 credits."
            },
            {
                "courseDescription": "Intro to Speech Composition",
                "courseNum": "100",
                "courseShortDescription": "COM ARTS",
                "description": "Learn how to give effective oral presentations in a variety of public speaking situations and to become better consumers of written and oral discourse. Learn basic composition and outlining skills as well as library research techniques.",
                "credits": "3 credits."
            },
            {
                "courseDescription": "Elem Matrix & Linear Algebra",
                "courseNum": "340",
                "courseShortDescription": "MATH",
                "description": "An introduction to linear algebra. Topics include matrix algebra, linear systems of equations, vector spaces, sub-spaces, linear dependence, span, basis, rank of matrices, determinants, linear transformations, coordinate representations, kernel, range, eigenvalues and eigenvectors, diagonalization, inner products and orthogonal vectors, symmetric matrices. Covers linear algebra topics in greater depth and detail than MATH\u00a0320. Formal techniques in mathematical argument [MATH\u00a0341] not covered.",
                "credits": "3 credits."
            },
            {
                "courseDescription": "Data Sci Programming II",
                "courseNum": "320",
                "courseShortDescription": "COMP SCI",
                "description": "Intermediate approach to Data Science programming using Python. Experience with basic tabular analysis in Python is assumed. Learn to implement data structures (e.g., graphs) to efficiently represent datasets. Software-engineering tools such as version control and Python virtual environments will be introduced, with an emphasis on reproducibility of analysis. Tracing and A/B testing will be introduced as techniques for generating meaningful datasets. Introduces basic classification, clustering, optimization, and simulation techniques. Plotting and visual communication will be emphasized throughout the course.",
                "credits": "4 credits."
            },
            {
                "courseDescription": "Skills for Business Analysis",
                "courseNum": "106",
                "courseShortDescription": "GEN BUS",
                "description": "Build fundamental skills and processes to develop a strong foundation in business analysis utilizing Excel. Learn the fundamentals of data construction, manipulation, summarization, analysis and presentation.",
                "credits": "1 credit."
            }
        ]
    },
    {
        "termDescription": "Fall 2022-2023",
        "gpa": 3.511,
        "courses": [
            {
                "courseDescription": "Introduction to Finance",
                "courseNum": "300",
                "courseShortDescription": "FINANCE",
                "description": "Concepts and techniques in corporate finance and investments. Topics include the financial environment, securities markets, financial markets, financial statements and analysis, working capital management, capital budgeting, cost of capital, dividend policy, asset valuation, investments, decision-making under uncertainty, mergers, options, and futures.",
                "credits": "3 credits."
            },
            {
                "courseDescription": "Operations Management",
                "courseNum": "300",
                "courseShortDescription": "OTM",
                "description": "Managing operations and supply chains is about people, information, equipment, and materials and how these are combined to produce and/or deliver goods and services to customers. Emphasis is on how systems and processes can be designed, managed, and improved to achieve operations excellence and competitive advantage.",
                "credits": "3 credits."
            },
            {
                "courseDescription": "Intro to Computer Engineering",
                "courseNum": "252",
                "courseShortDescription": "COMP SCI",
                "description": "Logic components built with transistors, rudimentary Boolean algebra, basic combinational logic design, basic synchronous sequential logic design, basic computer organization and design, introductory machine- and assembly-language programming.",
                "credits": "3 credits."
            },
            {
                "courseDescription": "Business Analytics I",
                "courseNum": "306",
                "courseShortDescription": "GEN BUS",
                "description": "Development of quantitative intuition through practical applications and use of analysis tools. Specifically, emphasis will be on how to manage, summarize, explore, and visualize databases. The essentials of probability will be introduced and applied to decision problems where there is uncertainty. Emphasis on hypothesis testing and regression analysis and include an introduction to simulation methods. Throughout, attention will be paid to effective communication of data analysis. The use of business cases will connect the course material to both real world settings and recent advances in data analysis, including big data and data mining.",
                "credits": "3 credits."
            },
            {
                "courseDescription": "Programming III",
                "courseNum": "400",
                "courseShortDescription": "COMP SCI",
                "description": "The third course in our programming fundamentals sequence. It presumes that students understand and use functional and object-oriented design and abstract data types as needed. This course introduces balanced search trees, graphs, graph traversal algorithms, hash tables and sets, and complexity analysis and about classes of problems that require each data type. Students are required to design and implement using high quality professional code, a medium sized program, that demonstrates knowledge and use of latest language features, tools, and conventions. Additional topics introduced will include as needed for projects: inheritance and polymorphism; anonymous inner classes, lambda functions, performance analysis to discover and optimize critical code blocks. Students learn about industry standards for code development. Students will design and implement a medium size project with a more advanced user-interface design, such as a web or mobile application with a GUI and event- driven implementation; use of version-control software.",
                "credits": "3 credits."
            }
        ]
    },
    {
        "termDescription": "Spring 2022-2023",
        "gpa": 3.600,
        "courses": [
            {
                "courseDescription": "Financial Reporting I",
                "courseNum": "301",
                "courseShortDescription": "ACCT I S",
                "description": "Examines current and emerging financial accounting theory and techniques used to measure and report financial information to investors, creditors, and other external users. Emphasizes asset and income determination, preparation and interpretation of financial statements, and related disclosure requirements.",
                "credits": "3 credits."
            },
            {
                "courseDescription": "Finc Mrkts,Instns&Econ Actv",
                "courseNum": "305",
                "courseShortDescription": "FINANCE",
                "description": "An analysis of the U.S. financial system, its responsiveness to and impact on economic activity and policy, its procedures for assessing and pricing risks on various financial instruments, and its role in the allocation of funds to different sectors in the economy.",
                "credits": "3 credits."
            },
            {
                "courseDescription": "Machine Organizatn&Progrmng",
                "courseNum": "354",
                "courseShortDescription": "COMP SCI",
                "description": "An introduction to fundamental structures of computer systems and the C programming language with a focus on the low-level interrelationships and impacts on performance. Topics include the virtual address space and virtual memory, the heap and dynamic memory management, the memory hierarchy and caching, assembly language and the stack, communication and interrupts/signals, compiling and assemblers/linkers.",
                "credits": "3 credits."
            },
            {
                "courseDescription": "Intro to Discrete Mathematics",
                "courseNum": "240",
                "courseShortDescription": "COMP SCI",
                "description": "Basic concepts of logic, sets, partial order and other relations, and functions. Basic concepts of mathematics (definitions, proofs, sets, functions, and relations) with a focus on discrete structures: integers, bits, strings, trees, and graphs.  Propositional logic, Boolean algebra, and predicate logic. Mathematical induction and recursion. Invariants and algorithmic correctness. Recurrences and asymptotic growth analysis. Fundamentals of counting.",
                "credits": "3 credits."
            },
            {
                "courseDescription": "Business Analytics II",
                "courseNum": "307",
                "courseShortDescription": "GEN BUS",
                "description": "Emphasis on hands-on experience with many commonly used analytic methodologies using the modeling and optimization tools available on almost every professional desktop. The focus is predictive and prescriptive analytics. Predictive approaches use historical data to infer causal relationships and forecast future outcomes from a given action. Prescriptive methods take this a step further, helping managers formulate decision models that identify optimal actions given a set of circumstances.",
                "credits": "3 credits."
            }
        ]
    },
    {
        "termDescription": "Fall 2023-2024",
        "gpa" : 3.676,
        "courses": [
            {
                "courseDescription": "Introduction to Databases",
                "courseNum": "322",
                "courseShortDescription": "INFO SYS",
                "description": "Introduction to database management systems with a focus on relational databases. Covers designing, creating, populating, managing, and retrieving data from databases. SQL will be used with a focus on querying for business applications and intelligence, data retrieval for summary reports, and data visualization. Offers a brief introduction to non-relational databases for business applications.",
                "credits": "3 credits."
            },
            {
                "courseDescription": "Introduction to Algorithms",
                "courseNum": "577",
                "courseShortDescription": "COMP SCI",
                "description": "Basic paradigms for the design and analysis of efficient algorithms: greed, divide-and-conquer, dynamic programming, reductions, and the use of randomness. Computational intractability including typical NP-complete problems and ways to deal with them.",
                "credits": "4 credits."
            },
            {
                "courseDescription": "Calc--Functns of Variables",
                "courseNum": "234",
                "courseShortDescription": "MATH",
                "description": "Introduction to calculus of functions of several variables; calculus on parameterized curves, derivatives of functions of several variables, multiple integrals, vector calculus.",
                "credits": "4 credits."
            },
            {
                "courseDescription": "Derivative Securities",
                "courseNum": "330",
                "courseShortDescription": "FINANCE",
                "description": "Pricing and uses of the most common derivatives including options, forward contracts, futures contracts, and swaps.",
                "credits": "3 credits."
            },
            {
                "courseDescription": "Building User Interfaces",
                "courseNum": "571",
                "courseShortDescription": "COMP SCI",
                "description": "Introduces software development of user interfaces (UIs).\u00a0 Build competence in implementing UIs using state-of-the-art (1) UI paradigms, such as event-driven interfaces, direct-manipulation interfaces, and dialogue-based interaction; (2) methods for capturing, interpreting, and responding to different forms of user input and states, including pointing, text entry, speech, touch, gestures, user activity, context, and physiological states; and (3) platform-specific UI development APIs, frameworks, and toolkits for multiple platforms including web/mobile/desktop interfaces, natural user interfaces, and voice user interfaces. Learn about the fundamental concepts, technologies, algorithms, and methods in building user interfaces, implement UIs using of state-of-the-art UI development tools, and build a UI development portfolio.",
                "credits": "3 credits."
            }
        ]
    },
    {
        "termDescription": "Spring 2023-2024",
        "courses": [
            {
                "courseDescription": "Investment Theory",
                "courseNum": "320",
                "courseShortDescription": "FIN",
                "description": "Structure and functioning of securities markets; principles of portfolio construction; models of the tradeoff between risk and expected return.",
                "credits": "3 credits."
            },
            {
                "courseDescription": "Introduction to Cryptocurrency and Web3",
                "courseNum": "365",
                "courseShortDescription": "FIN",
                "description": "",
                "credits": "3 credits."
            },
            {
                "courseDescription": "Introductory Managerial Accounting",
                "courseNum": "211",
                "courseShortDescription": "ACCT I S",
                "description": "Managerial accounting concepts relevant for decision-making; use of accounting information for planning, decision-making, and control of business operations in various management and business environments.",
                "credits": "3 credits."
            },
            {
                "courseDescription": "Marketing Management",
                "courseNum": "300",
                "courseShortDescription": "MARKETNG",
                "description": "Planning and controlling the elements of the marketing program; marketing organization, product and service, packaging, pricing, promotion and physical distribution.",
                "credits": "3 credits."
            },
            {
                "courseDescription": "Managing Organizations",
                "courseNum": "300",
                "courseShortDescription": "M H R",
                "description": "The management of people and organizations: diversity, attitude and job satisfaction, emotions, personality and value, individual and group decision making, motivation, teams, leadership, influence, strategy, organizational structure and culture, and change management.",
                "credits": "3 credits."
            },
            {
                "courseDescription": "Wellbeing in the Workplace",
                "courseNum": "365",
                "courseShortDescription": "M H R",
                "description": "",
                "credits": "3 credits."
            }
        ]
    },
    {
        "termDescription": "Fall 2024-2025",
        "courses": [
            {
                "courseDescription": "Business Law",
                "courseNum": "301",
                "courseShortDescription": "GEN BUS",
                "description": "History of legal development, contracts, agency, sale of goods, insurance.",
                "credits": "3 credits."
            },
            {
                "courseDescription": "Intro to Operating Systems",
                "courseNum": "537",
                "courseShortDescription": "COMP SCI",
                "description": "Input-output hardware, interrupt handling, properties of magnetic tapes, discs and drums, associative memories and virtual address translation techniques. Batch processing, time sharing and real-time systems, scheduling resource allocation, modular software systems, performance measurement and system evaluation.",
                "credits": "4 credits."
            },
            {
                "courseDescription": "Intro-Artificl Intelligence",
                "courseNum": "540",
                "courseShortDescription": "COMP SCI",
                "description": "Principles of knowledge-based search techniques, automatic deduction, knowledge representation using predicate logic, machine learning, probabilistic reasoning. Applications in tasks such as problem solving, data mining, game playing, natural language understanding, computer vision, speech recognition, and robotics.",
                "credits": "3 credits."
            },
            // {
            //     "courseDescription": "Intro-Combinatorics",
            //     "courseNum": "475",
            //     "courseShortDescription": "COMP SCI",
            //     "description": "Problems of enumeration, distribution, and arrangement. Inclusion-exclusion principle. Generating functions and linear recurrence relations. Combinatorial identities. Graph coloring problems. Finite designs. Systems of distinct representatives and matching problems in graphs. Potential applications in the social, biological, and physical sciences. Puzzles. Problem solving.",
            //     "credits": "3 credits."
            // },
            {
                "courseDescription": "Contemporary Moral Issues",
                "courseNum": "341",
                "courseShortDescription": "PHILOS",
                "description": "A philosophical study of some of the major moral issues in contemporary society, such as those concerning abortion, euthanasia, punishment, property, politics, sex, nuclear disarmament, and world hunger.",
                "credits": "3-4 credits."
            },
            {
                "courseDescription": "The Digital Age",
                "courseNum": "150",
                "courseShortDescription": "HIST SCI",
                "description": "An introduction to the history of the computer from the 1940s to the present day, major developments in computer science and technology in their historical contexts, and recent trends in computing and society. We learn about machines, but emphasize the study of people: the institutions, scientists, workers, and social movements that invented, facilitated, and transformed digital technology in the 20th and early 21st century.",
                "credits": "3 credits."
            }
        ]
    },

]
export default function Education(){
    return  (
        <Container>
            <h2 className="my-4">Education</h2>
            <EducationIntroduction></EducationIntroduction>
            <h2 className="my-4">My Semesters</h2>
            <SemesterCourses semesters={semesters} />
        </Container>
    );
}