import React from 'react';
import './ArtistComponent.css';  // Import CSS file for component-specific styles

const ArtistComponent = ({ artist }) => {
  const renderImages = () => {
    // Render up to 3 images, or fewer if there are fewer images available
    return artist.images.slice(0, 1).map((image, index) => (
      <img key={index} src={image.url} alt={`Artist ${artist.name}`} className="artist-image" />
    ));
  };

  return (
    <div className="artist-item">
      <div className="artist-info">
        <h3>{artist.name}</h3>
        <p>Followers: {artist.followers.toLocaleString()}</p>
        <a href={artist.url} target="_blank" rel="noopener noreferrer" className="spotify-link">Spotify Profile</a>
      </div>
      <div className="artist-images">
        {renderImages()}
      </div>
    </div>
  );
}

export default ArtistComponent;

