
import React from 'react';
import './TrackComponent.css';  // Import CSS file for component-specific styles

const TrackComponent = ({ track }) => {
  const renderArtists = () => {
    // Display artists as comma-separated list
    return track.artists.join(', ');
  };

  const renderImages = () => {
    // Render up to 3 images, or fewer if there are fewer images available
    return track.images.slice(0, 1).map((image, index) => (
      <img key={index} src={image.url} alt={`Track ${track.name}`} className="track-image" />
    ));
  };

  return (
    <div className="track-item">
      <div className="track-info">
        <h3>{track.name}</h3>
        <p>Artists: {renderArtists()}</p>
        <a href={track.url} target="_blank" rel="noopener noreferrer" className="spotify-link">Listen on Spotify</a>
      </div>
      <div className="track-images">
        {renderImages()}
      </div>
    </div>
  );
}

export default TrackComponent;

