import { useEffect } from "react";

export default function RythmRoadtripSuccess(){
    useEffect(()=> {
        const urlParams = new URLSearchParams(window.location.search);
        const code = urlParams.get('code');
        const accessToken = urlParams.get('access_token'); 
        const refreshToken = urlParams.get('refresh_token');
        
        if (code !== null){
            window.localStorage.setItem('authCode', code);
            window.localStorage.setItem('firstLoad', true);
        } 
        if (accessToken !== null) window.localStorage.setItem('access_token', accessToken);
        if (refreshToken !== null) window.localStorage.setItem('refresh_token', refreshToken);
        // window.location.href = 'http://localhost:3000/rythmroadtrip'
        window.location.href = process.env.NODE_ENV === "development" ? 'http://localhost:3000/rythmroadtrip' :'https://rythmresume.org/rythmroadtrip'
    }, [])
        
}