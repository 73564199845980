import React from 'react';

export default function Projects(){
    return (
        <div style={{display:"grid", marginTop:"50px", marginLeft:"50px"}}>
            <div>Projects Page in progress...</div>
            <a href={`${process.env.PUBLIC_URL}/resume.pdf`}>Check out my resume</a>
            <a href={`https://apps.apple.com/us/developer/arete-mobile-llc/id1571586086`}>Or my apps!</a>
        </div>
        
    );
}