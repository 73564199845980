import React from 'react';
import { Row, Col, Image, Card } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';

const EducationIntroduction = () => {
  return (
    <Card className="mb-4 section-card">
      <Card.Body>
        <Row>
          <Col md={4} className="text-center">
            <Image src="bascom.jpg" rounded fluid />
          </Col>
          <Col md={8}>
            <h3>University of Wisconsin Madison</h3>
            <p><strong>Major:</strong> Computer Science and Finance</p>
            <p><strong>Graduation Date:</strong> May 2025</p>
            <p><strong>GPA:</strong> 3.64</p>
            <p><strong>Skills:</strong> Java, Pyhon, C, React, SQL, Algorithms, Data Structures, Databases, Derivitave Securities, Excel, Financial Markets, Crypto, Portfolio Management</p>
            <p>
            I am a senior student at UW Madison studying finance and computer science. I originally was planning on studying Businesses because it has been an interest of mine for a while and I believe it will help me become suited for my professional career. I began taking Computer Science classes in high school because technology has been something I have always been very curious about. After gaining more experience in the subject I realized it is something I enjoy and would like to pursue, along with business, to help people in their everyday lives.
            </p>
          </Col>
        </Row>
      </Card.Body>
    </Card>
  );
};

export default EducationIntroduction;
