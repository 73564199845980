import React from 'react';
import { Container, Row, Col, Card } from 'react-bootstrap';
import './Activities.css'; 
import './Content.css'

const Activities = () => {
  return (
    <Container>
      <h2 className="my-4">Activities</h2>
      <p>Throughout my academic journey, I have actively participated in various extracurricular activities that have significantly contributed to my personal and professional growth. These activities have allowed me to develop critical skills, build meaningful relationships, and make a positive impact in my community. Below, you'll find detailed information about two of my key involvements: the Consulting Club and the Business Fraternity.</p>
      
      <Card className="mb-4 section-card">
        <Card.Body>
          <Row>
            <Col md={4} className="image-col">
              <Card.Img src="badgerconsulting_logo.jpeg" alt="Consulting Club" className="fixed-image" />
            </Col>
            <Col md={8}>
              <Card.Title>Badger Consulting</Card.Title>
              <Card.Text>
                Badger Consulting is a student-run consulting group at the University of Wisconsin-Madison, dedicated to providing pro-bono consulting services to small businesses in Dane County. The organization offers students practical, real-world business experience in industry research, consumer insights, marketing analytics, and financial viability, under the mentorship of the Wisconsin Small Business Development Center (WI SBDC). With a commitment to clients, community, and students, Badger Consulting aims to make an economic impact locally while fostering an inclusive environment that cultivates professional relationships and lifelong friendships.
              </Card.Text>
            </Col>
        </Row>
        <Row>
            <Card.Subtitle className="mt-3 mb-2">Housing Data</Card.Subtitle>
            <Col md={4}>
                <Card.Text>
                    While working as an analyst at BC, I applied my data analytic skills that I have learned in my business classes. I gathered the US census data for Oneida county, WI to analyze housing trends for our client. I found that a small number of homes, 5%, were built in the last 11 years. Additionally, I found that there had been a decrease in homes being built, and that secondary homes had grown in the past 10 years but on the decline in the past couple. 
                </Card.Text>
            </Col>
            <Col md={4} className="image-col">
                <Card.Img src="proj1pic1.png" alt="proj1pic1" className="fixed-image" />
            </Col>
            <Col md={4} className="image-col">
                <Card.Img src="proj1pic2.png" alt="proj1pic1" className="fixed-image" />
            </Col>
            
         </Row>
         <Row>
            <Card.Subtitle className="mt-3 mb-2">Health Data</Card.Subtitle>
            <Col md={4}>
                <Card.Text>
                    On a different project, I was able to apply my skills from both computer science courses and business courses. I imported data into my jupyter notebook from CDC Diabetes Health Indicators, a dataset in the UCI Machine Learning Repo. While I wasnt concerned about diabeaties, this dataset offered other health indicators that I could track by age groups to see when people begin using their mobility. I then used pandas and sql to analyze the data. I found the following...
                </Card.Text>
            </Col>
            <Col md={4} className="image-col">
                <Card.Img src="proj2pic1.png" alt="proj1pic1" className="fixed-image" />
            </Col>
            <Col md={4} className="image-col">
                <Card.Img src="proj2pic2.png" alt="proj1pic1" className="fixed-image" />
            </Col>
            
         </Row>
        </Card.Body>
      </Card>
      <Card className="mb-4 section-card">
        <Card.Body>
          <Row>
            <Col md={4} className="image-col">
              <Card.Img src="akpsi.png" alt="Business Fraternity" className="fixed-image" />
            </Col>
            <Col md={8}>
              <Card.Title>Alpha Kappa Psi</Card.Title>
              <Card.Text>
                Alpha Kappa Psi is a coed business fraternity on the UW Madison campus. Members go through professional development, participate in community service, professional events, brotherhood events, and DEI events. This org is filled with highly driven, intellegent, and talented individuals across all majors. 
              </Card.Text>
            </Col>
          </Row>
        </Card.Body>
      </Card>
    </Container>
  );
};

export default Activities;
