import './SemesterCourses.css'
import React from 'react';
import { Container, Card, Accordion} from 'react-bootstrap';
import "../content/Content.css"

const SemesterCourses = ({ semesters }) => {
    // Reverse the semesters array to display in reverse order
    let reversedSemesters = [...semesters].reverse();
 

    return (
        <Container className="semester-courses-container">
            {reversedSemesters.map((semester, index) => (
                <Card key={index} className="mb-4 ">
                    <Card.Header>
                        <h2>{ semester.termDescription }</h2>
                    </Card.Header>
                           <Accordion>
                            {
                                semester.courses.map((c, i) => (
                                    <Accordion.Item eventKey={i}>
                                        <Accordion.Header>{c.courseShortDescription} {c.courseNum} - {c.courseDescription}</Accordion.Header>
                                        <Accordion.Body>
                                            <p><strong>Description:</strong> {c.description}</p>
                                            <p><strong>Credits:</strong> {c.credits}</p>

                                        </Accordion.Body>
                                    </Accordion.Item>
                                ))
                            }
                           </Accordion>
                </Card>
            ))}
        </Container>
    );
};

export default SemesterCourses;
